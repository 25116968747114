import React, { useEffect, useState } from 'react';
import './ContentHome.css';
import ReactCardSlider from 'react-card-slider-component';
import Carousel from 'carousel-react-rcdev'



function ContentHome() {
    const [slides, getSlides] = React.useState([])
    const [slides2, getSlides2] = React.useState([])
    const sliderClick = () => {
        console.log("hello world");
    }
    useEffect(() => {
        getSlides([
            { image: `${window.location.origin}/img/slider/mag-silver.png`, title: "Mag Silver", description: "Mag Silver", clickEvent: sliderClick },
            { image: `${window.location.origin}/img/slider/reyna-silver.webp`, title: "Reyna Silver", description: "Reyna Silver", clickEvent: sliderClick },
            { image: `${window.location.origin}/img/slider/infinitum-copper.png`, title: "Infinitum Copper", description: "Infinitum Copper", clickEvent: sliderClick },
            { image: `${window.location.origin}/img/slider/reyna-gold.jpeg`, title: "Reyna Gold", description: "Reyna Gold", clickEvent: sliderClick },
            { image: `${window.location.origin}/img/slider/prismo-metals.png`, title: "Prismo Metals", description: "Prismo Metals", clickEvent: sliderClick },
        ]);
        getSlides2([
            // { image: <img key='0' src={window.location.origin + "/img/slider2/excellon.webp"} alt='Excellon Resources Inc' title='Excellon Resources Inc' /> },
            // { image: <img key='1' src={window.location.origin + "/img/slider2/lakeshore.png"} alt='Lake Shore Gold' title='Lake Shore Gold' /> },
            // { image: `<img id='2' src='${window.location.origin}/img/slider2/grayd.jpg' alt='Grayd Resources' title='Grayd Resources' />` },
            // { image: `<img id='3' src='${window.location.origin}/img/slider2/minaurum.jpeg' alt='Minaurum Gold' title='Minaurum Gold' />` },
            // { image: `<img id='4' src='${window.location.origin}/img/slider2/goldenminerals.png' alt='Golden Minerals' title='Golden Minerals' />` },
            // { image: `<img id='5' src='${window.location.origin}/img/slider2/minefinders.png' alt='Minefinders Corporation Ltd' title='Minefinders Corporation Ltd' />` },
            // { image: `<img id='6' src='${window.location.origin}/img/slider2/kenneott.png' alt='Kenneott Copper' title='Kenneott Copper' />` },
            // { image: `<img id='7' src='${window.location.origin}/img/slider2/mulatos.png' alt='Mulatos property of Alamos Gold' title='Mulatos property of Alamos Gold' />` },
        ]);
    }, []);
    return (
        <div className='container'>
            <div className='row marginTop20'>
                <h1 className='primaryTitle'>IMDEX/Cascabel: Mineral Exploration and Mining in Mexico </h1>
            </div>
            <div className='row marginTop20'>
                {/* <h1 className='secondaryTitle'>Solicitud y Gestión de permisos.</h1> */}
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            La exploración minera en México ha cambiado significativamente en los últimos años y la necesidad de la mano de obra continua ha ido evolucionando.
                            Se han realizado importantes descubrimientos que se han convertido en programas de perforación avanzados que requieren apoyo geológico y técnico
                            altamente capacitado. Además, hay muchas necesidades que requieren una gama completa de trabajos en tierra y de estudios de reconocimiento.
                            Nosotros podemos proporcionarles rápidamente a las personas experimentadas que se necesitaran para llevar a cabo cualquier tipo de programa y así
                            poder eliminar los muchos problemas que se puede tener a la hora de importar o contratar personal en México.
                        </p>
                    </div>
                </div>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/inicio2.jpg`} width={"100%"} />
                </div>
            </div>

            <div id="AcercaDe" className='row marginTop20'>
                <h1 className='primaryTitle'>ACERCA DE NOSOTROS </h1>
            </div>
            <div className='row marginTop20'>
                {/* <h1 className='secondaryTitle'>Solicitud y Gestión de permisos.</h1> */}
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/inicio.jpg`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Desde 1988 el personal de Minera Cascabel S.A. DE C.V. y IMDEX Inc. ha llevado a cabo proyectos de exploración minera para mas de 100 clientes en México y otros 6 países de América Latina. Los trabajos incluyen una amplia gama de servicios que se han realizado con éxito a lo largo de nuestra trayectoria, incluyendo algunos proyectos ubicados en las zonas ​más conflictivas de México y América Latina.
                        </p>
                        <p>
                            Minera Cascabel/IMDEX cuenta actualmente con un equipo conformado por geólogos, peritos, personal de apoyo dentro y fuera del campo, contadores y abogados, que entienden las necesidades de la comunidad minera a nivel nacional e internacional, tenemos la experiencia necesaria para brindar nuestros servicios con éxito en México y en otros países de América Latina. Nuestro personal bilingüe facilita la comunicación entre los clientes y nuestro personal de campo.
                        </p>
                    </div>
                </div>
            </div>
            <div id="Clientes" className='row marginTop20'>
                <h1 className='primaryTitle'>CLIENTES Y ANTECEDENTES</h1>
            </div>
            <div className='row'>
                <div className='col col-12'>
                    <div className='textBox'>
                        <p>
                            La exploración minera en México ha cambiado significativamente en los últimos años y la necesidad de la mano de obra continua ha ido evolucionando. Se han realizado importantes descubrimientos que se han convertido en programas de perforación avanzados que requieren apoyo geológico y técnico altamente capacitado. Nosotros podemos proporcionarles rápidamente a las personas experimentadas que se necesitan para llevar a cabo cualquier tipo de programa y así poder eliminar los muchos problemas que se puede tener a la hora de importar o contratar personal en México.
                        </p>
                        <p>
                            El diverso staff que tenemos nos ha permitido llevar a cabo con éxito la operación de bastantes proyectos mineros. Manejamos operaciones completas de llave en mano, en las que llevamos todos los aspectos fiscales, contables y administrativos de sus proyectos. También tenemos contratos con una amplia cantidad de consultores especializados, según sea necesario.
                        </p>
                    </div>
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>Clientes actuales</h1>
            </div>
            <div className='row marginTop20'>
                <ReactCardSlider slides={slides} />
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>Descubrimientos Exitosos.</h1>
            </div>
            <div className='row marginTop20'>
                <h1 className='tab'>“JUANICIPIO” (ZACATECAS, MEXICO)</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img className='square-img' src={`${window.location.origin}/img/juancipio.png`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <img className='square-img' src={`${window.location.origin}/img/juancipio2.png`} width={"100%"} />
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='tab'>“PLATOSA” (DURANGO, MEXICO)</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <img className='square-img' src={`${window.location.origin}/img/platosa.png`} width={"100%"} />
                </div>
                <div className='col col-5'>
                    <img className='square-img' src={`${window.location.origin}/img/platosa2.png`} width={"100%"} />
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>Empresas importantes con las que hemos trabajado</h1>
            </div>
            <div className='row marginTop20 slider2'>
                <Carousel>
                    <img key='0' src={window.location.origin + "/img/slider2/excellon.webp"} alt='Excellon Resources Inc' title='Excellon Resources Inc' />
                    <img key='1' src={window.location.origin + "/img/slider2/lakeshore.png"} alt='Lake Shore Gold' title='Lake Shore Gold' />
                    <img key='2' src={window.location.origin + "/img/slider2/grayd.jpeg"} alt='Grayd Resources' title='Grayd Resources' />
                    <img key='3' src={window.location.origin + "/img/slider2/minaurum.jpeg"} alt='Minaurum Gold' title='Minaurum Gold' />
                    <img key='4' src={window.location.origin + "/img/slider2/goldenminerals.jpeg"} alt='Golden Minerals' title='Golden Minerals' />
                    <img key='5' src={window.location.origin + "/img/slider2/minefinders.jpeg"} alt='Minefinders Corporation Ltd' title='Minefinders Corporation Ltd' />
                    <img key='6' src={window.location.origin + "/img/slider2/kenneott.png"} alt='Kenneott Copper' title='Kenneott Copper' />
                    <img key='7' src={window.location.origin + "/img/slider2/mulatos.png"} alt='Mulatos property of Alamos Gold' title='Mulatos property of Alamos Gold' />
                </Carousel>
            </div>
            <div id="Servicios" className='row marginTop20'>
                <h1 className='primaryTitle'>SERVICIOS</h1>
            </div>
            <div className='row'>
                <div className='col col-12'>
                    <div className='textBox'>
                        <p>
                            La exploración minera en México ha cambiado significativamente en los últimos años y la necesidad de la mano de obra continua ha ido evolucionando. Se han realizado importantes descubrimientos que se han convertido en programas de perforación avanzados que requieren apoyo geológico y técnico altamente capacitado. Nosotros podemos proporcionarles rápidamente a las personas experimentadas que se necesitan para llevar a cabo cualquier tipo de programa y así poder eliminar los muchos problemas que se puede tener a la hora de importar o contratar personal en México.
                        </p>
                        <p>
                            El diverso staff que tenemos nos ha permitido llevar a cabo con éxito la operación de bastantes proyectos mineros. Manejamos operaciones completas de llave en mano, en las que llevamos todos los aspectos fiscales, contables y administrativos de sus proyectos. También tenemos contratos con una amplia cantidad de consultores especializados, según sea necesario.
                        </p>
                    </div>
                </div>
            </div>
            <div className='row marginTop20'>
                <h1 className='secondaryTitle'>Solicitud y Gestión de permisos.</h1>
            </div>
            <div className='row'>
                <div className='col col-5'>
                    <div className='textBox'>
                        <p>
                            Conseguir los permisos necesarios, requiere la presentación de informes preliminares, intermedios y finales a un número cada vez mayor de agencias gubernamentales. Este puede ser un proceso que requiera mucho tiempo por lo que debe iniciarse lo antes posible para evitar demoras en etapas como la construcción de carreteras, la perforación y la explotación.
                        </p>
                        <p>
                            Los requisitos necesarios para presentar las solicitudes de los permisos han cambiado significativamente, haciendo mas delicadas las relaciones que se deben de tener con los propietarios o ejidatarios de las superficies, esto con el fin de obtener su consentimiento. Nuestro personal es consciente de la importancia de establecer buenas relaciones desde el inicio del proyecto. Contamos con la experiencia en todas las etapas del proceso y nos mantenemos actualizados a medida que aumentan los requisitos. Además, contamos con contactos para llevar acabo los informes ambientales necesarios para las "declaraciones de impacto ambiental" de la preproducción.
                        </p>
                        <ul className='primary-list'>
                            <li>Programas de exploración completos o parciales.</li>
                            <li>Administración de proyectos total o parcial.</li>
                            <li>Servicios contables, fiscales y administrativos.</li>
                            <li>Gestión de permisos y concesiones.</li>
                            <li>Supervisión y coordinación de programas de perforación.</li>
                            <li>Negociaciones con ejidatarios o propietarios de las tierras</li>
                        </ul>
                        <b>*Contamos con el servicio de llave en mano en el cual llevamos absolutamente todos los aspectos necesarios para llevar el proyecto con éxito y de manera eficiente conforme a sus necesidades.</b>
                    </div>
                </div>
                <div className='col col-5'>
                    <img src={`${window.location.origin}/img/servicios.jpg`} width={"100%"} />
                </div>
            </div>
            <div id="Contacto" className='row marginTop20'>
                <h1 className='primaryTitle'>Contacto</h1>
            </div>
            <div className=''>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.5310749804835!2d-110.9596014!3d29.119522299999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce80df755e7e85%3A0xbfbf53846ddf7b79!2sMinera%20Cascabel!5e0!3m2!1ses-419!2smx!4v1659431904394!5m2!1ses-419!2smx" width="100%" height="450" style={{ border: 0 }} loading="lazy" referrerPolicy={"no-referrer-when-downgrade"}></iframe>
            </div>
            <div className='row'>
                <div className='col col-12'>
                    <div className='textBox'>
                        <p>
                            <span><b>Dirección:</b> Avenida 13, #100. Colonia Bugambilias.</span> <br />
                            <span><b>Código Postal:</b> 83140, Hermosillo, Sonora, México. </span><br />
                            <span><b>Teléfono:</b> 6622157477</span>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ContentHome