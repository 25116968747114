import React from 'react';
import axios from 'axios';
import './HeroSection.css';

function HeroSection() {
    React.useEffect(() => {

    }, [])

    return (
        <div id="Inicio" className='hero-container' style={{ backgroundImage: `url(${window.location.origin}/img/bg_home.jpg)` }}>
            <img src='/img/logo_large.png' width="400" alt="HVE" title="HVE" />
        </div >
    )
}

export default HeroSection