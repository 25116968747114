import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';
import './Navbar.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }
    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);


    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='/img/navbar_logo.png' alt="YoDesarrollo" title="yoDesarrollo" height="70" />
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <a href='#Inicio' className='nav-links' onClick={closeMobileMenu}>
                                Inicio
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#AcercaDe' className='nav-links' onClick={closeMobileMenu}>
                                Acerca de
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#Clientes' className='nav-links' onClick={closeMobileMenu}>
                                Clientes y Antecedentes
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#Servicios' className='nav-links' onClick={closeMobileMenu}>
                                Servicios
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href='#Contacto' className='nav-links' onClick={closeMobileMenu}>
                                Contacto
                            </a>
                        </li>
                        {/* <li className='nav-item'>
                            <a href="mailto:contacto@hveng.net" target="_blank" rel='noreferrer' className='nav-links' onClick={closeMobileMenu}>
                                <i className="fa fa-envelope"></i>
                            </a>
                        </li>
                        <li className='nav-item'>
                            <a href="https://www.youtube.com/watch?v=NaGhNrr2p9o" target="_blank" rel='noreferrer' className='nav-links' onClick={closeMobileMenu}>
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar